/* eslint-disable */
import React, {useEffect, useState} from 'react'
import useFetchList from 'utils/useFetchList'
import { currencyFormatter } from '@3pp/utils';
import AnonymousIcon from '../../styles/assets/img/anonim.jpg'
import DefaultAvatar from 'components/DefaultAvatar'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const PopUp = (props) => {

  const [
    nextUrl,
    hasMore,
    fetchList,
    lists,
    isLoading
  ] = useFetchList(
    `${process.env.REACT_APP_CORE_API}/campaigns/${props.campaign.id}/donors?sort=verified&`
  )
  const [loadMore, setLoadMore] = useState(false);

  const Content = ({item}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          lineHeight: '1.4'
        }}
      >
        {
          item.is_anonymous ? (
            <img
              src={AnonymousIcon}
              alt="anonim user"
              style={{ width: '30px', marginRight: '10px', borderRadius: '50%' }}
            />
          ) : (
            item.user.string === '' ? (
              <DefaultAvatar name={item.user.name} size={30} />
            ) : (
              <img
                src={item.user.string}
                alt={item.user.name}
                style={{ width: '30px', marginRight: '10px', borderRadius: '50%' }}
              />
            )
          )
        }
        <span style={{ fontSize: '14px', color: '#3A3A3A'}}>
          {item.user.name}, Donasi Rp {currencyFormatter(item.invoice.base_donation)}
        </span>
      </div>
    )
  }

  const fetchToast = () => {
    let i = 0
    const intervalId = setInterval(function(){
      if(i >= 9){
        clearInterval(intervalId);
        if(nextUrl && hasMore) {
          setLoadMore(!loadMore)
        }
      }
      toast(<Content item={lists[i]} loadMore={loadMore} />)
      i++;
    }, 3000);
  }

  useEffect(() => {
    if(!isLoading && lists.length > 0) {
      fetchToast()
    }
    if(hasMore && loadMore){
      fetchList()
    }
  }, [isLoading, hasMore, loadMore])

  if (isLoading || !lists) return null

  return (
    <div>
      <ToastContainer
        limit={3}
        closeButton={false}
        hideProgressBar={true}
        position='bottom-center'
        // pauseOnFocusLoss
        // autoClose={false}
      />
    </div>
  )
}

export default PopUp
