
import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { fetchData } from '@3pp/utils'
import AmountRaised from 'components/AmountRaised'
import RunningText from 'components/RunningText'
import Qrcode from 'components/Qrcode'
import Allwidget from './Allwidget'

const Television = props => {
  const [campaign, setCampaign] = useState(null)
  const shortUrl = props.match.params.campaign
  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/campaign/${shortUrl}`,
        method: 'GET',
      })
      try {
        setCampaign(data.data[0])
      } catch(err){
        alert('nama campaign tidak ditemukan')
      }
    }
    fetchCampaign()
  }, [shortUrl])

  if (campaign === null) return null

  const isTotalDonation = props.match.params.widget === "total-donation"
  const isRunningText = props.match.params.widget === "running-text"
  const isQrcode = props.match.params.widget === 'qrcode'
  const isAllWidget = props.match.params.widget === 'all-widgets'

  return (
    <>
      {
        !isAllWidget ? (
          <Layout>
            {
              isTotalDonation && (
                <AmountRaised
                  totalDonation={campaign.donation_received}
                  shortUrl={`${process.env.REACT_APP_KITABISA_URL}/${shortUrl}`}
                  platform='youtubetv'
                />
              )
            }
            {
              isRunningText && (
                <RunningText campaign={campaign}/>
              )
            }
            {
              isQrcode && (
                <Qrcode campaign={campaign}/>
              )
            }

          </Layout>
        ) : (
          <Allwidget campaign={campaign}/>
        )
      }
    </>
  )
}

export default Television
