import React from 'react'
import { Input } from './style';

const InputCampaign = (props) => {
  return (
    <Input
      type="text"
      placeholder="Input Campaign Url"
      onChange={props.onChange}
    />
  )
}

export default InputCampaign
