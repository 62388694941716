import React, { useEffect, useState } from 'react'
import { fetchData } from '@3pp/utils'

import {
  Container,
  ScanText,
  QRwrapper, VisitText,
  VisitCampaign
} from './style'

const Qrcode = (props) => {
  const [qr, setQr] = useState(null)

  const campaignId = props.campaign.id
  useEffect(() => {
    const fetchQris = async () => {

      const additionalHeaders = {
        Authorization: `Bearer ${process.env.REACT_APP_QR_TOKEN}`
      }

      const data = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/internal/qris/static/list/${campaignId}`,
        method: 'GET',
        additionalHeaders
      })
      try {
        setQr(data.data[0])
      } catch(err){
        alert('QR Code not found on this campaign')
      }
    }
    fetchQris()
  }, [campaignId])

  if (qr === null) return null
  return (
    <Container>
      <VisitText>
        <span>
          Kunjungi
        </span>
      </VisitText>
      <VisitCampaign>
        <span style={{ display: 'inline-block'}}>
          {process.env.REACT_APP_QRCODE}
        </span>
        <span style={{ wordBreak: 'break-word' }}>
          {props.campaign.short_url}
        </span>
      </VisitCampaign>
      <ScanText>
        <span>
          atau Scan
        </span>
      </ScanText>
      <QRwrapper>
        <img
          src={qr.qr_image}
          alt="qrImage"
          width="128"
        />
      </QRwrapper>
    </Container>
  )
}

export default Qrcode
