import React from 'react'
import { ButtonSubmit } from './style'

const Button = (props) => {
  return (
    <ButtonSubmit
      onClick={props.onClick}
    >
      Submit
    </ButtonSubmit>
  )
}

export default Button
