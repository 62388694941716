
import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { fetchData } from '@3pp/utils'
import AmountRaised from 'components/AmountRaised'
import PopUp from 'components/PopUp'

const InApp = props => {
  const [campaign, setCampaign] = useState(null)
  const shortUrl = props.match.params.campaign

  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/campaign/${shortUrl}`,
        method: 'GET',
      })

      try {
        setCampaign(data.data[0])
      } catch(err){
        alert('nama campaign tidak ditemukan')
      }
    }
    fetchCampaign()
  }, [shortUrl])

  if (campaign === null) return null

  const handleClick = () => {
    window.open(`${process.env.REACT_APP_KITABISA_URL}/${shortUrl}`,'_blank');
  }

  const isTotalDonation = props.match.params.widget === "total-donation"
  const isPopup = props.match.params.widget === "donation-popup"

  return (
    <Layout>
      {
        isTotalDonation && (
          <AmountRaised
            onClick={() => handleClick()}
            totalDonation={campaign.donation_received}
            shortUrl={`${process.env.REACT_APP_KITABISA_URL}/${shortUrl}`}
            platform='inapp'
          />
        )
      }
      {
        isPopup && (
          <PopUp campaign={campaign}/>
        )
      }

    </Layout>
  )
}

export default InApp
