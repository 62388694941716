import React from 'react'
import { SelectDropdown } from './style'

const Select = props => (

  <SelectDropdown
    id={props.id}
    style={props.style}
    defaultValue={props.defaultValue}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
  >
    {props.children}
  </SelectDropdown>

)

export default Select
