import styled, { css } from 'styled-components'

export const Rectangle = styled.div`
  background-color: #FFFFFF;
  border-radius: ${props => props.flex === 'column' ? '5px 5px 0 0' : '5px'};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.23);
  min-width: ${props => props.flex === 'column' ? '300px' : '272px'};
  display: flex;
  flex-flow: ${props => props.flex === 'column' ? 'column' : 'row wrap'};

  .powered-logo {
    /* flex:1; */
    background-image: linear-gradient(180deg, #4FC7F9 0%, #3399F6 100%);
    padding: ${props => props.flex === 'column' ? '12px 7px 4px' : '18px 7px'};
    border-radius: 5px 5px 0 0;
    ${props => props.flex === 'column' && css`
      flex-flow: column;
      display:flex;
    `}
  }

  .title-amount {
      text-align: center;
      color: #fff;
      font-size: 16px;
    }

  .info-box{
    padding: 6px 10px;
    flex: 1;
    flex-flow: column wrap;
    display: flex;
    &__title {
      color: #3A3A3A;
      font-size: 10px;
      font-weight: 400;
    }
    &__amount {
      font-size: ${props => props.flex === 'column' ? "30px" : "12px"};
      color: #3A3A3A;
      font-weight: 700;
      ${props => props.flex === 'column' && css `
        text-align: center
      `}
    }
    &__shortUrl {
      color: #49BDF8;
      font-size: 12px;
    }
  }
`

