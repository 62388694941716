import styled from 'styled-components';

export const Input = styled.input`
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  color: #444;
  padding: .5em 1.5em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  margin: 8px 0;
`
