import React from 'react'
import styles from './styles.module.scss'

export const DefaultAvatar = ({ name='Kitabisa', size=48 }) => {
  let initials = name.match(/\b\w/g) || []
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  return (
    <div className={styles['default-avatar']} style={{ width: size, height: size }}>
      <span style={{ fontSize: '10px'}}>{initials}</span>
    </div>
  )
}
export default DefaultAvatar
