import styled from 'styled-components'

export const Main = styled.main`
  background-color: #F5F5F5;
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 40px 48px;
  align-items: center;
  justify-content: center;
`

