/* eslint-disable */
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'

import AnonymousIcon from 'styles/assets/img/anonim.jpg'
import DefaultAvatar from 'components/DefaultAvatar'

import { currencyFormatter } from '@3pp/utils';
import useFetchList from 'utils/useFetchList'

import { SiteMessage } from './style';


const RunningText = (props) => {

  const [
    nextUrl,
    hasMore,
    fetchList,
    lists,
    isLoading
  ] = useFetchList(
    `${process.env.REACT_APP_CORE_API}/campaigns/${props.campaign.id}/donors?sort=verified&`
  )

  if (isLoading || !lists) return null

  return (
    <SiteMessage>
      <div className="marquee-content">
        <span className="item-collection-1">
          {
            lists.map(list => (
              <span className="item1" key={list.id}>
                {
                  list.is_anonymous ? (
                    <img
                      src={AnonymousIcon}
                      alt="anonim user"
                      style={{ width: '25px', marginRight: '10px' }}
                    />
                  ) : (
                    list.user.string === '' ? (
                      <DefaultAvatar name={list.user.name} size={25} />
                    ) : (
                      <img
                        src={list.user.string}
                        alt={list.user.name}
                        style={{ width: '25px', marginRight: '10px' }}
                      />
                    )
                  )
                }
                <span className="donors-info">
                  {list.user.name} Rp {currencyFormatter(list.invoice.base_donation)}
                  {
                    list.comment !== '' && (
                      <span className="donors-info__comment">
                        <strong> - </strong> {list.comment}
                      </span>
                    )
                  }
                </span>
                <FontAwesomeIcon
                  icon={faCircle}
                  color="#10A8E5"
                  size="xs"
                  style={{fontSize: '8px', marginLeft:'20px'}}
                />
              </span>
            ))
          }
        </span>
        <span className="item-collection-2">
          {
            lists.map(list => (
              <span className="item2" key={list.id}>
                {
                  list.is_anonymous ? (
                    <img
                      src={AnonymousIcon}
                      alt="anonim user"
                      style={{ width: '25px', marginRight: '10px' }}
                    />
                  ) : (
                    list.user.string === '' ? (
                      <DefaultAvatar name={list.user.name} size={25} />
                    ) : (
                      <img
                        src={list.user.string}
                        alt={list.user.name}
                        style={{ width: '25px', marginRight: '10px' }}
                      />
                    )
                  )
                }
                <span className="donors-info">
                  {list.user.name} Rp {currencyFormatter(list.invoice.base_donation)}
                  {
                    list.comment !== '' && (
                      <span className="donors-info__comment">
                        <strong> - </strong> {list.comment}
                      </span>
                    )
                  }
                </span>
                <FontAwesomeIcon
                  icon={faCircle}
                  color="#10A8E5"
                  size="xs"
                  style={{fontSize: '8px', marginLeft:'20px'}}
                />
              </span>
            ))
          }
        </span>
      </div>
    </SiteMessage>
  )
}

export default RunningText
