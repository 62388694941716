import styled from 'styled-components'

export const Container = styled.section`
  background: white;
  border-radius: 13px 13px 0 0;
  padding: 10px;
`

export const VisitCampaign = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #49BDF8;
  border-radius: 0 5px 5px;
  width:170px;
  min-height:51px;
  text-align:center;
  font-size:16px;
  line-height: 19.9px;
  font-weight: bold;
`

export const VisitText = styled.div`
  width: 170px;

  span {
    background-color: #49BDF8;
    border: 1px solid #49BDF8;
    border-radius: 5px 5px 0 0;
    color: white;
    padding: 0 14px;
    font-size: 14px;
  }
`

export const ScanText = styled.div`
  width: 170px;

  span {
    background-color: #49BDF8;
    border: 1px solid #49BDF8;
    border-radius: 5px 5px 0 0;
    color: white;
    padding: 0 14px;
    font-size: 14px;
  }
`

export const QRwrapper = styled.div`
  border: 1px solid #49BDF8;
  border-radius: 0 5px 5px;
  padding: 10px 20px;
`
