import styled from 'styled-components';

export const SiteMessage = styled.section`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;

.marquee-content {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    animation: marquee 75s linear infinite;
}
.item-collection-1 {
  display: inline-flex;
  align-items: center;
  /* margin-right: 20px; */
  animation: swap 75s linear infinite;
  background: white;
  padding: 10px;
  position: relative;
  left: 0%;
}
.item-collection-2 {
  display: inline-flex;
  align-items: center;
  /* margin-right: 20px; */
  background: white;
  padding: 10px;
}
@keyframes swap {
  0%, 50% {
    left: 0%;
  }
  50.01%,
  100% {
    left: 100%;
  }
}
/* .marquee-content:hover {
  animation-play-state: paused
} */

.donors-info {
  color: #3A3A3A;
  font-size: 18px;
  font-weight:bold;

  &__comment {
    font-weight: normal;
  }
}
.item1 {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}
.item2 {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}
/* Transition */

@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}
`
