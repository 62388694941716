/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react'
import Layout from 'components/Layout'
import { useHistory } from "react-router-dom";

import Button from 'components/Button'
import Select from 'components/Select'
import InputCampaign from 'components/InputCampaign'

const Home = props => {
  const [platform, setPlatform] = useState('')
  const [campaignName, setCampaignName] = useState('')
  const [widget, setWidget] = useState('')
  const history = useHistory();

  const handleChange = (event) => {
    setCampaignName(event.target.value)
  }

  const submit = () => {
    history.push(`/${campaignName}/${platform}/${widget}`)
  }
  return (
    <Layout>
      <form style={{ display: 'flex', flexFlow: 'column wrap', justifyContent: 'center'}}>
        <Select
          defaultValue={'defaultPlatform'}
          onChange={(event) => setPlatform(event.target.value)}
        >
          <option  value="defaultPlatform" disabled>
            Select a Platform
          </option>
          <option  value='inapp'>
            In App
          </option>
          <option value='tv'>
            Television
          </option>
          <option value='youtube'>
            Youtube
          </option>
        </Select>
        {
          platform !== '' && (
            <InputCampaign onChange={handleChange}/>
          )
        }
        {
          (platform !== '' && campaignName !== '') && (
            <Select
              defaultValue={'defaultWidget'}
              onChange={(event) => setWidget(event.target.value)}
            >
              <option  value="defaultWidget" disabled>
                Select a Widget
              </option>
              {
                platform !== 'inapp' && (
                  <option  value='qrcode'>
                   QR Code
                  </option>
                )
              }
              <option value='total-donation'>
                Total Donation
              </option>
              {
                platform === 'inapp' && (
                  <option value='donation-popup'>
                    Donation popup
                  </option>
                )
              }
              {
                platform !== 'inapp' && (
                  <option value='running-text'>
                    Running text
                  </option>
                )
              }
              {
                platform !== 'inapp' && (
                  <option value='all-widgets'>
                    All Widgets
                  </option>
                )
              }
            </Select>
          )
        }
        {
          (platform !== '' && campaignName !== '' && widget !== '') && (
            <Button type="submit" onClick={submit} />
          )
        }
      </form>
    </Layout>
  )
}

export default Home
