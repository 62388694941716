/* eslint-disable */
import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { fetchData } from '@3pp/utils'

const useFetchList = url => {
  const [hasMore, setHasMore] = useState(false)
  const [lists, setLists] = useState([])
  const [nextUrl, setNextUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFething] = useState(false)

  const fetchList = async () => {
    if(isFetching) return
    setIsFething(true)
    try {
      const nextParam = nextUrl ? `next=${nextUrl}` : ''
      const lists = await fetchData({
        url: `${url}${nextParam}`
      })
      setNextUrl(lists.next)
      setLists(prevState => prevState.concat(lists.data))
      lists.data.length === 0
        ? setHasMore(false)
        : setHasMore(true)
      // just setIsLoading to false after first fetch
      isLoading === true && setIsLoading(false)
    } catch (err) {
      Sentry.setExtra('error', err)
      Sentry.captureException(err)
      throw err
    } finally {
      setIsFething(false)
    }
  }

  useEffect(() => {
    nextUrl ? setHasMore(true) : setHasMore(false)
  }, [nextUrl])

  useEffect(() => {
    fetchList()
  }, [])

  return [nextUrl, hasMore, fetchList, lists, isLoading]
}

export default useFetchList
