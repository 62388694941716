import styled from 'styled-components'

export const ButtonSubmit = styled.button`
  display: flex;
  font-size: 14px;
  font-family: sans-serif;
  color: #fff;
  padding: .5em 4.5em;
  box-sizing: border-box;
  margin: 0;
  border: transparent;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  margin: 8px 0;
  background: #49BDF8;
  justify-content: center;
`
