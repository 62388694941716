import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from 'containers/Home'
import InApp from 'containers/InApp'
import Television from 'containers/Television'
import Youtube from 'containers/Youtube'
import { FailedPage } from '@3pp/ui-library'
import 'styles/main.scss'
const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact render={(props) => (
          <Home {...props} />
        )} />
        <Route path="/:campaign/inapp/:widget" exact render={(props) => (
          <InApp {...props} />
        )} />
        <Route path="/:campaign/tv/:widget" exact render={(props) => (
          <Television {...props} />
        )} />
        <Route path="/:campaign/youtube/:widget" exact render={(props) => (
          <Youtube {...props} />
        )} />
        <Route render={(props) => (
          <FailedPage 
            title="Halaman tidak ditemukan" 
            information="Saat ini kamu belum bisa mengakses halaman ini, kamu bisa kembali lagi nanti."
            from={window.location.href.replace(props.history.location.pathname,'')}
          />
        )} />
      </Switch>
    </Router>
  );
};

export default App;
