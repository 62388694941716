import React from 'react'
import AmountRaised from 'components/AmountRaised'
import RunningText from 'components/RunningText'
import Qrcode from 'components/Qrcode'
import { ContainerWidget } from './style'


const Allwidget = (props) => {
  return (
    <ContainerWidget>
      <div className="content">
        <div className="running-text">
          <RunningText campaign={props.campaign}/>
        </div>
        <div className="amount-raised">
          <AmountRaised
            totalDonation={props.campaign.donation_received}
            shortUrl={`${process.env.REACT_APP_KITABISA_URL}/${props.campaign.shortUrl}`}
            platform='youtubetv'
          />
        </div>
        <div className="qrcode">
          <Qrcode campaign={props.campaign}/>
        </div>

      </div>
    </ContainerWidget>
  )
}

export default Allwidget
