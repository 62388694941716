import styled from 'styled-components';

export const ContainerWidget = styled.main`
  background-color: #00B140;
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;

  .content {
    display: flex;
    flex-wrap: wrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    bottom: 0;
    min-height: 100vh;
  }

  .running-text {
    align-self: flex-end;
    min-width: 200px;
    flex: 1;
    z-index: 2;
  }

  .amount-raised{
    align-self: flex-end;
    z-index: 2;
  }

  .qrcode {
    align-self: flex-end;
    z-index: 2;
  }
`
