import React from 'react'
import { currencyFormatter } from '@3pp/utils';
import PropTypes from 'prop-types'
import { Rectangle } from './style'

const AmountRaised = (props) => {
  const platform = props.platform
  return (
    <>
      {
        platform === "inapp" && (
          <Rectangle onClick={() => props.onClick()}>
            <img
              className="powered-logo"
              src="/images/amountRaised_mobile.svg" alt="powered-logo"
            />
            <div className="info-box">
              <span className="info-box__title">
                Donasi Terkumpul
              </span>
              <span className="info-box__amount">
                Rp {currencyFormatter(props.totalDonation || '')}
              </span>
              <span className="info-box__shortUrl">
                {props.shortUrl}
              </span>
            </div>
          </Rectangle>
        )
      }
      {
        platform === "youtubetv" && (
          <Rectangle flex="column">
            <div
              className="powered-logo"
            >
              <img src="/images/amountRaised_youtubetv.svg" alt="powered-logo"/>
              <span className="title-amount">Donasi Terkumpul</span>
            </div>
            <div className="info-box">
              <span className="info-box__amount">
                Rp {currencyFormatter(props.totalDonation || '')}
              </span>
            </div>
          </Rectangle>
        )
      }
    </>
  )
}

AmountRaised.propTypes = {
  totalDonation: PropTypes.number,
  shortUrl: PropTypes.string,
  onClick: PropTypes.func,
}

export default AmountRaised
